<template>
  <div>
    <v-app-bar
        color="deep-purple accent-4"
        :fixed="true"
        dense
        dark>
      <div style="margin-right: 15px;" @click="back">
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <v-toolbar-title>TMS Order Task Status</v-toolbar-title>
    </v-app-bar>

    <div
        style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%; padding: 10px;">

      <div style="padding: 8px;"></div>

      <v-select
          :items="status_items"
          label="Status"
          :value="selected_status"
          @change="handleStatusChange"
      ></v-select>

      <template v-if="isUnloadingTask && isNextStatusComplete">
        <div style="padding: 8px;"></div>
        <v-select
            :items="quantity_check_items"
            label="Actual quantity"
            v-model="selected_quantity_check"
            @change="handleQuantityCheckChange"
        ></v-select>

        <v-text-field
            v-if="selected_quantity_check === 'not_same'"
            :label="'Input actual quantity' + ' *'"
            v-model="actual_quantity"
            type="number"
            :rules="[quantityRules.required, quantityRules.nonNegative]"
            :error-messages="quantity_error"
        ></v-text-field>
      </template>

      <div style="text-align: center; margin-bottom:27px;">
        <v-btn v-if="current_task && current_task.status_str != 'Complete'"
               x-large color="success" dark :disabled="save_status_btn_disable" @click="saveTmsOrderTaskStatus">Save</v-btn>
      </div>

      <v-timeline dense>
        <v-timeline-item
            v-for="log in status_logs"
            :key="log.id"
            small
        >
          <span slot="opposite">{{ log.status_str }}</span>
          <v-card class="elevation-2">
            <v-card-title class="headline">{{ log.status_str }}</v-card-title>
            <v-card-text>
              <div>{{ log.staff.first_name }} set status to <span style="font-weight: bold;font-style:italic;">{{ log.status_str }}</span></div>
              <div>{{ log.created_at }}</div>
              <template v-if="log.lat_lng && log.lat_lng.longitude">
                lat: <span style="color: #26a2ff">{{ log.lat_lng.latitude }}</span>
                lng: <span style="color: #26a2ff">{{ log.lat_lng.longitude }}</span>
              </template>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>

    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
    >
      {{ snackbar_txt }}
      <v-btn
          style="display: none;"
          color="pink"
          text
          @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { get, post } from '@/utils/request.js'
import { EH_MOBILE_GLOBAL, EH_MOBILE_FUN } from '@/helper.js'

export default {
  data () {
    return {
      snackbar: false,
      snackbar_txt: '',
      snackbar_timeout: 1500,


      // page: 1,
      tms_route_id: null,
      tms_order_id: null,
      tms_order_task_id: null,
      tms_order: {},
      tms_goods: [],
      tms_task: [],
      status_items: [],
      next_status_item: [],
      status_logs: [],
      current_task: null,
      selected_status: null,
      save_status_btn_disable: false,

      quantity_check_items: [
        { text: 'Same as request', value: 'same' },
        { text: 'Not same as request', value: 'not_same' }
      ],
      selected_quantity_check: 'same',
      actual_quantity: '',
      quantity_error: '',
      quantityRules: {
        required: value => !!value || 'Actual quantity is required',
        nonNegative: value => value >= 0 || 'Quantity must be non-negative'
      }
    }
  },
  computed: {
    isUnloadingTask() {
      return this.current_task && this.current_task.task_type === 2
    },
    isNextStatusComplete() {
      return this.selected_status && this.status_items.find(
        item => item.value === this.selected_status
      )?.text === 'Complete'
    }
  },
  watch: {
    tab_select (newval, oldval) {
      console.log([newval, oldval])
    },
  },
  methods: {
    getTmsOrderDetail: function (callbck) {
      let url = EH_MOBILE_GLOBAL.get_tms_order_detail + '?tms_route_id=' + this.tms_route_id + '&tms_order_id=' + this.tms_order_id +
          '&tms_order_task_id=' + this.tms_order_task_id
      get(url).then((response) => {
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }

        this.tms_order = data.order
        this.tms_goods = data.goods
        this.tms_task = data.tasks

        for (let i in this.tms_task) {
          let task = this.tms_task[i]
          if (task.id == this.tms_order_task_id) {
            this.current_task = task
          }
        }

        if (typeof callbck == 'function') {
          callbck(response)
        }
      })
    },
    handleStatusChange(val){
      this.selected_status = val
    },
    getStatusLog: function (order_detail_response) {
      console.log(order_detail_response)
      console.log([new Date().getTime(), '131'])
      let url = EH_MOBILE_GLOBAL.get_tms_order_task_status_info + '?tms_task_id=' + this.tms_order_task_id
      get(url).then((response) => {
        console.log([new Date().getTime(), '134'])
        let data = response.data
        if (data.status != 'success') {
          this.snackbar_txt = data.reason
          this.snackbar = true
          return
        }
        // console.log(response)
        let next = false
        for (let i in data.all_status) {
          let s = data.all_status[i]
          let tmp = {
            'text': s.display,
            'value': s.num,
          }

          this.status_items.push(tmp)

          if (next) {
            next = false
            this.next_status_item.push(tmp)
            this.selected_status = tmp.value
          }

          console.log(tmp.text,'-----------',this.current_task.status_str)

          if (tmp.text == this.current_task.status_str) {
            next = true
          }
        }

        this.status_logs = data.logs
      })
    },
    back: function () {
      this.$router.go(-1)
    },
    handleQuantityCheckChange(val) {
      this.selected_quantity_check = val
      if (val === 'same') {
        this.actual_quantity = ''
        this.quantity_error = ''
      }
    },
    validateQuantity() {
      if (!this.isUnloadingTask || !this.isNextStatusComplete) return true
      
      if (this.selected_quantity_check === 'not_same') {
        if (!this.actual_quantity) {
          this.quantity_error = 'Actual quantity is required'
          return false
        }
        if (this.actual_quantity < 0) {
          this.quantity_error = 'Quantity must be non-negative'
          return false
        }
      }
      return true
    },
    saveTmsOrderTaskStatus() {
      if (!this.validateQuantity()) {
        this.snackbar_txt = 'Please check actual quantity input'
        this.snackbar = true
        return
      }

      const data = {
        tms_task_id: this.tms_order_task_id,
        staus_id: this.selected_status,
      }

      if (this.isUnloadingTask && this.isNextStatusComplete) {
        data.quantity_check = this.selected_quantity_check
        if (this.selected_quantity_check === 'not_same') {
          data.actual_quantity = this.actual_quantity
        }
      }

      this.save_status_btn_disable = true
      
      post(EH_MOBILE_GLOBAL.save_tms_order_task_status, EH_MOBILE_FUN.serialize(data)).then((response) => {
        this.save_status_btn_disable = false
        if (response.data.status == 'success') {
          this.getTmsOrderDetail((r) => {
            this.getStatusLog(r)
          })
        } else {
          this.snackbar_txt = response.data.reason
          this.snackbar = true
        }
      })
    }
  },
  created () {
    this.tms_route_id = this.$route.params.tms_route_id
    this.tms_order_id = this.$route.params.tms_order_id
    this.tms_order_task_id = this.$route.params.tms_order_task_id

    this.getTmsOrderDetail((r) => {
      this.getStatusLog(r)
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
